import { Box, styled } from '@mui/material';
import React, { useState } from 'react';
import { useParams } from 'react-router';

import { TabNavigation } from './TabNavigation/TabNavigation';
import { getBottomTabsData, getTopTabsData } from './TabNavigation/utils';

const JobViewTabs: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const { id } = useParams<{ id: string }>();
  const jobId = Number(id);

  const handleTabChange = (_: React.SyntheticEvent, newValue: number): void => {
    setSelectedTab(newValue);
  };

  const topTabsData = getTopTabsData(jobId);
  const bottomTabsData = getBottomTabsData(jobId);
  const allTabsData = [...topTabsData, ...bottomTabsData];

  return (
    <Container>
      <TabNavigation
        onChange={handleTabChange}
        selectedTab={selectedTab}
        jobId={jobId}
        topTabsData={topTabsData}
        bottomTabsData={bottomTabsData}
      />

      {allTabsData.map((tab) => (
        <TabContentContainer key={tab.value} display={selectedTab === tab.value ? 'block' : 'none'}>
          {tab.content}
        </TabContentContainer>
      ))}
    </Container>
  );
};
export default JobViewTabs;

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.contrastText,
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
  padding: theme.spacing(4),
  width: '100%',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2),
    flexDirection: 'column',
    alignItems: 'end',
    height: '100%',
  },
}));

const TabContentContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  marginBottom: theme.spacing(1),
  marginLeft: theme.spacing(8),
  marginTop: theme.spacing(2),
  maxWidth: theme.breakpoints.values.lg,
  width: '60%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    margin: 0,
    maxWidth: '100%',
  },
}));
