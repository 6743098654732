export enum ConnectActionButtonsDataTestIds {
  EXPRESS_INTEREST_BUTTON = 'express-interest-button',
  SAVE_SCHOOL_BUTTON = 'connect-save-school-button',
}

export enum ConnectAlertsBannerDataTestIds {
  NOT_SUBSCRIBED_BANNER = 'connect-alerts-banner-not-subscribed',
  SUBSCRIBED_BANNER = 'connect-alerts-banner-subscribed',
}

export enum ConnectAuthenticatedAdminModalDataTestIds {
  ADMIN_MODAL = 'connect-authenticated-admin-modal',
  CANCEL_BUTTON = 'connect-authenticated-admin-modal-cancel-button',
  LOGOUT_BUTTON = 'connect-authenticated-admin-modal-logout-button',
}

export enum ConnectCandidatePreferencesDataTestIds {
  COMPLETE_PROFILE_TEXT = 'connect-candidate-profile-complete-profile-title-text',
}

export enum ConnectJobDiscoveryFiltersDataTestIds {
  DISTANCE_FILTER = 'connect-job-discovery-distance-filter-dropdown',
  DISTRICT_FILTER = 'connect-job-discovery-district-filter-dropdown',
  GRADE_FILTER = 'connect-job-discovery-grade-level-filter',
  LOCATION_INPUT = 'connect-job-discovery-location-filter-input',
  SCHOOL_SEARCH_FILTER = 'connect-job-discovery-school-search-filter',
  SUBJECTS_FILTER = 'connect-job-discovery-subject-filter',
  UPDATE_DISTANCE_FROM_LOCATION_SUBMIT_BUTTON = 'connect-job-discovery-location-filter-submit-button',
}

export enum ConnectJobDiscoveryGatedResultsCardDataTestIds {
  GATED_RESULTS_CARD = 'connect-job-discovery-gated-results-card-container',
  HEADER_TEXT = 'connect-job-discovery-gated-results-card-header-text',
  SIGNUP_BUTTON = 'gated-results-card-signup-button',
}

export enum ConnectJobDiscoverySchoolCardDataTestIds {
  SCHOOL_CARD = 'connect-job-discovery-school-card-container',
}

export enum ConnectLazyRegistrationLoginSignUpPageDataTestIds {
  EMAIL_INPUT = 'connect-lazy-registration-login-sign-up-page-email-input',
  SUBMIT_BUTTON = 'connect-lazy-registration-login-sign-up-page-submit-button',
}

export enum ConnectMarketingInputAndButtonDataTestIds {
  COMPLETE_PROFILE_BUTTON = 'connect-marketing-banner-complete-profile-button',
  EMAIL_INPUT = 'marketing-email-input-jobs-discovery',
  SIGN_UP_BUTTON = 'marketing-sign-up-button',
}

export enum ConnectMoreInfoSliderDataTestIds {
  MORE_INFO_SLIDER = 'connect-job-discovery-more-info-slider',
}

export enum ConnectValuePropBannerDataTestIds {
  HEADER_TEXT = 'connect-job-discovery-value-prop-banner-header-text',
  SUB_HEADER_TEXT = 'connect-job-discovery-value-prop-banner-sub-header-text',
  SUB_HEADER_LINK_TEXT = 'connect-job-discovery-value-prop-banner-sub-header-link-text',
  IMAGE_ASSET = 'connect-job-discovery-value-prop-banner-image-asset',
}

export enum ConnectProfileFormDataTestIds {
  FIRST_NAME_INPUT = 'connect-profile-flow-profile-form-first-name-input',
  FIRST_NAME_QUESTION_LABEL = 'connect-profile-flow-profile-form-first-name-question-label',
  LAST_NAME_INPUT = 'connect-profile-flow-profile-form-last-name-input',
  LAST_NAME_QUESTION_LABEL = 'connect-profile-flow-profile-form-last-name-question-label',
  PHONE_NUMBER_INPUT = 'connect-profile-flow-profile-form-phone-number-input',
  PHONE_NUMBER_QUESTION_LABEL = 'connect-profile-flow-profile-form-phone-number-question-label',
  STATE_PREFERENCES_DROPDOWN = 'connect-profile-flow-profile-form-state-preferences-dropdown',
  STATE_PREFERENCES_QUESTION_LABEL = 'connect-profile-flow-profile-form-state-preferences-question-label',
  BUTTON_GROUP = 'connect-profile-flow-profile-form-button-group',
  SAVE_AND_CONTINUE_BUTTON = 'connect-profile-flow-profile-form-save-and-continue-button',
}

export enum ConnectQualificationsFormDataTestIds {
  ADD_CERTIFICATION_BUTTON = 'connect-profile-flow-qualifications-form-add-certification-button',
  CERTIFICATION_STATE_ERROR_TEXT = 'connect-profile-flow-qualifications-form-certification-state-error-text',
  CERTIFICATION_STATE_SELECT = 'connect-profile-flow-qualifications-form-certification-state-select',
  CERTIFICATION_SUBJECT_ERROR_TEXT = 'connect-profile-flow-qualifications-form-certification-subject-error-text',
  CERTIFICATION_SUBJECT_SELECT = 'connect-profile-flow-qualifications-form-certification-subject-select',
  CERTIFICATION_TOGGLE = 'connect-profile-flow-qualifications-form-certification-toggle',
  BUTTON_GROUP = 'connect-profile-flow-qualifications-form-button-group',
  BACK_BUTTON = 'connect-profile-flow-qualifications-form-back-button',
  CONTINUE_BUTTON = 'connect-profile-flow-qualifications-form-continue-button',
  REMOVE_CERTIFICATION_BUTTON = 'connect-profile-flow-qualifications-form-remove-certification-button',
  RESUME_ERROR_TEXT = 'connect-profile-flow-qualifications-form-resume-error-text',
  TEACHING_EXPERIENCE_ERROR_TEXT = 'connect-profile-flow-qualifications-form-teaching-experience-error-text',
  TEACHING_EXPERIENCE_SELECT = 'connect-profile-flow-qualifications-form-teaching-experience-select',
  UPLOAD_RESUME_TOOL = 'connect-profile-flow-qualifications-form-upload-resume-tool',
  DELETE_CERTIFICATION_BUTTON = 'connect-profile-flow-qualifications-form-delete-certification-button',
}

export enum ConnectPreferencesFormDataTestIds {
  CONNECT_OPPORTUNITY_NO = 'connect-profile-flow-preferences-form-connect-opportunity-no-toggle',
  CONNECT_OPPORTUNITY_QUESTION_LABEL = 'connect-profile-flow-preferences-form-connect-opportunity-question-label',
  CONNECT_OPPORTUNITY_RADIO_GROUP = 'connect-profile-flow-preferences-form-connect-opportunity-radio-group',
  CONNECT_OPPORTUNITY_YES = 'connect-profile-flow-preferences-form-connect-opportunity-yes-toggle',
  DISTANCE_DROPDOWN = 'connect-profile-flow-preferences-form-distance-dropdown',
  DISTANCE_QUESTION_LABEL = 'connect-profile-flow-preferences-form-distance-question-label',
  GRADES_DROPDOWN = 'connect-profile-flow-preferences-form-grades-dropdown',
  GRADES_QUESTION_LABEL = 'connect-profile-flow-preferences-form-grades-question-label',
  JOB_SEEKING_STATUS_NO = 'connect-profile-flow-preferences-form-job-seeking-status-no-toggle',
  JOB_SEEKING_STATUS_QUESTION_LABEL = 'connect-profile-flow-preferences-form-job-seeking-status-question-label',
  JOB_SEEKING_STATUS_RADIO_GROUP = 'connect-profile-flow-preferences-form-job-seeking-status-radio-group',
  JOB_SEEKING_STATUS_YES = 'connect-profile-flow-preferences-form-job-seeking-status-yes-toggle',
  LOCATION_INPUT = 'connect-profile-flow-preferences-form-location-text-field-input',
  LOCATION_QUESTION_LABEL = 'connect-profile-flow-preferences-form-location-question-label',
  BACK_BUTTON = 'connect-profile-flow-preferences-form-back-button',
  BUTTON_GROUP = 'connect-profile-flow-preferences-form-button-group',
  SAVE_AND_CONTINUE_BUTTON = 'connect-profile-flow-preferences-form-save-and-continue-button',
  SCHOOLS_TO_HIDE_FROM_DROPDOWN = 'connect-profile-flow-preferences-form-schools-to-hide-from-dropdown',
  SCHOOLS_TO_HIDE_FROM_QUESTION_LABEL = 'connect-profile-flow-preferences-form-schools-to-hide-from-question-label',
  SUBJECTS_DROPDOWN = 'connect-profile-flow-preferences-form-subjects-dropdown',
  SUBJECTS_QUESTION_LABEL = 'connect-profile-flow-preferences-form-subjects-question-label',
}

export enum ConnectProfileFlowDataTestIds {
  CONNECT_ANNOUNCEMENT_BANNER = 'connect-profile-flow-announcement-banner',
  LEARN_MORE_LINK = 'connect-profile-flow-learn-more-link',
  STEPPER = 'connect-profile-flow-stepper',
  STEPPER_MOBILE = 'connect-profile-flow-stepper-mobile',
  STEPPER_DESKTOP = 'connect-profile-flow-stepper-desktop',
  PROFILE_FORM = 'connect-profile-flow-profile-form',
  QUALIFICATIONS_FORM = 'connect-profile-flow-qualifications-form',
  PREFERENCES_FORM = 'connect-profile-flow-preferences-form',
}
