import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { TabbedJobViewJob } from './types';

export const useQueryGetsJobPostingDetailsByJobId = (
  jobId: number,
  options?: Omit<UseQueryOptions<TabbedJobViewJob, AxiosError>, 'queryKey' | 'queryFn'>
): UseQueryResult<TabbedJobViewJob, AxiosError> => {
  return useQuery({
    queryKey: ['tabbedJobViewJobPostingDetailsByJobId', jobId],
    queryFn: () => axios.get(`/api/roles/${jobId}/details`).then((res) => res.data),
    enabled: Number.isInteger(jobId),
    ...options,
  });
};
