import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { ConnectGatedActions } from 'features/Connect/utils/connectEnums';
import { ChangeEvent } from 'react';
import { useLocation } from 'react-router-dom';
import { Actions, School } from 'types/connectTypes';
import { User } from 'types';
import { Screen, Screens, GatedActionResponse } from './types';
import { History } from 'history';
import ExpressInterestAPI from 'features/Connect/api/expressInterestAPI';
import { connectRedirectUrls, connectUrlParams } from '../JobDiscovery/constants';
import { CONNECT_JOBBOARD_STATES } from 'utils/constants';
import { buildUrl } from 'utils/util';
import {
  MAX_FILE_NAME_CHARACTER_LENGTH,
  MAX_FILE_SIZE_IN_BYTES,
} from 'features/Connect/utils/connectConstants';
import GeolocationAPI from 'features/Connect/api/geolocationAPI';
import { Option } from 'sharedComponents/Select/types';
import { userTypeToId } from 'utils/typedEnums';
import { Box, Link, Typography } from '@mui/material';
import { ConnectProfileFlowDataTestIds } from 'data-testids/ConnectDataTestIds';
import { nimbleTheme } from 'theme';

export const MISSOURI_STATE_ID = 25;
const connectRootUrl = '/connect';
const connectProfileRootUrl = '/connect/profile';
const connectPreferencesRootUrl = '/connect/preferences';
const connectQualificationsRootUrl = '/connect/qualifications';

export const NO_FILE_SELECTED_ERROR_MESSAGE = 'Please select a file to upload';

export const PROFILE_FORM_ERROR_MESSAGES = {
  firstName: 'Please enter your first name',
  lastName: 'Please enter your last name',
  phoneNumber: 'Please enter your phone number',
  statePreference: 'Please select your state preference',
};

export function checkFileNameAndSize(event: ChangeEvent<HTMLInputElement>): {
  fileData: File;
  error: string;
} {
  const fileData = event.target.files[0];
  let error;

  if (typeof fileData === 'undefined' || fileData.size === 0) {
    error = 'Upload is empty. Please try again.';
  }
  if (fileData.size > MAX_FILE_SIZE_IN_BYTES) {
    error = 'File size must be smaller than 5 MB. Please try a different file.';
  }
  if (fileData.name.length > 215) {
    error = `Please ensure your file name is fewer than ${MAX_FILE_NAME_CHARACTER_LENGTH} characters`;
  }

  return { fileData, error };
}

export const formatResumeFileName = (resumeFilePath: string): string => {
  if (!resumeFilePath) {
    return '';
  } else {
    const path = resumeFilePath.split('?')[0];
    const pathArr = path.split('/');
    return pathArr[pathArr.length - 1].substring(14);
  }
};

export function moreInfoOnClickHandler(school: School, actions: Actions, vacancyId: string): void {
  if (vacancyId !== '0') {
    actions.setSelectedVacancyId(vacancyId);
  }
  actions.setSelectedSchool(school);
  actions.setMoreInfoSliderIsOpen(true);
}

export function createButtonUrl({
  basePath,
  schoolId,
  action,
  jobboardState,
}: {
  basePath: string;
  schoolId?: string;
  action?: string;
  jobboardState?: string;
}): string {
  let url = basePath;
  const queryParams = [];

  if (jobboardState) {
    queryParams.push(`${connectUrlParams.REDIRECT}=${jobboardState}`);
  }

  if (action === ConnectGatedActions.ALERT) {
    queryParams.push(`action=${action}`);
  } else if (schoolId && action) {
    queryParams.push(`school=${schoolId}&action=${action}`);
  }

  if (queryParams.length) {
    url += `?${queryParams.join('&')}`;
  }

  return url;
}

export const getProfileFlowPage = (pathname: string, flowScreens: Screens): Screen => {
  const segments = pathname.split('/');
  const pageSegment = segments[segments.length - 1];
  return flowScreens[pageSegment];
};

const getSearchParams = (searchParams) => {
  return {
    gatedAction: searchParams.get('action')?.toLowerCase(),
    schoolId: searchParams.get('school'),
    jobboardState: searchParams.get(connectUrlParams.REDIRECT),
  };
};

export const getGatedActionDisplayInfo = (
  hasConditionalRequirements = false
): GatedActionResponse => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { search, pathname } = useLocation();
  const searchParams = new URLSearchParams(search);
  const { gatedAction, schoolId, jobboardState } = getSearchParams(searchParams);

  let flowDisplayInformation;
  switch (gatedAction) {
    case ConnectGatedActions.ALERT:
      flowDisplayInformation = {
        stepper: {
          steps: ['Contact Info', 'Add Alert', 'Get Discovered'],
          mobileSteps: ['Contact Info', 'Add Alert', 'Get Discovered'],
        },
        alreadyLoggedInRedirect: createButtonUrl({
          basePath: `${connectPreferencesRootUrl}${jobboardState}`,
        }),
        screens: {
          login: {
            step: null,
            headerText: 'Create job alert',
            continueToUrl: createButtonUrl({
              basePath: connectProfileRootUrl,
              action: gatedAction,
              jobboardState: jobboardState,
            }),
            backUrl: null,
            primaryButtonLabel: 'Continue',
            primaryButtonLabelMobile: 'Continue',
            requiredFields: ['email'],
          },
          signup: {
            step: null,
            headerText: 'Create job alert',
            continueToUrl: createButtonUrl({
              basePath: connectProfileRootUrl,
              action: gatedAction,
              jobboardState: jobboardState,
            }),
            backUrl: null,
            primaryButtonLabel: 'Continue',
            primaryButtonLabelMobile: 'Continue',
            requiredFields: ['email'],
          },
          profile: {
            step: 0,
            headerText: 'Add your contact info',
            continueToUrl: createButtonUrl({
              basePath: connectPreferencesRootUrl,
              action: gatedAction,
              jobboardState: jobboardState,
            }),
            backUrl: null,
            primaryButtonLabel: 'Continue',
            primaryButtonLabelMobile: 'Continue',
            requiredFields: ['firstName', 'lastName', 'openToContact'],
          },
          preferences: {
            step: 1,
            headerText: 'Set up your alert',
            continueToUrl: createButtonUrl({
              basePath: connectQualificationsRootUrl,
              action: gatedAction,
              jobboardState: jobboardState,
            }),
            backUrl: createButtonUrl({
              basePath: connectProfileRootUrl,
              action: gatedAction,
              jobboardState: jobboardState,
            }),
            primaryButtonLabel: 'Continue',
            primaryButtonLabelMobile: 'Continue',
            requiredFields: ['subject', 'grade', 'distance'],
          },
          qualifications: {
            step: 2,
            headerText: 'Get discovered by principals',
            continueToUrl: createButtonUrl({
              basePath: connectRootUrl,
              action: gatedAction,
              jobboardState: jobboardState,
            }),
            backUrl: createButtonUrl({
              basePath: connectPreferencesRootUrl,
              action: gatedAction,
              jobboardState: jobboardState,
            }),
            primaryButtonLabel: 'Save & Finish',
            primaryButtonLabelMobile: 'Finish',
            requiredFields: [],
          },
        },
      };
      break;
    default:
      flowDisplayInformation = {
        stepper: {
          steps: ['Profile', 'Qualifications', 'Preferences'],
          mobileSteps: ['Profile', 'Qualifications', 'Preferences'],
        },
        alreadyLoggedInRedirect: createButtonUrl({
          basePath: connectRootUrl,
          action: gatedAction,
          schoolId: schoolId,
          jobboardState: jobboardState,
        }),
        screens: {
          login: {
            step: null,
            headerText: 'Log in to your account',
            continueToUrl: createButtonUrl({
              basePath: connectProfileRootUrl,
              action: gatedAction,
              schoolId: schoolId,
              jobboardState: jobboardState,
            }),
            backUrl: null,
            primaryButtonLabel: 'Continue',
            primaryButtonLabelMobile: 'Continue',
            requiredFields: ['email'],
          },
          signup: {
            step: null,
            headerText: 'Create Profile',
            subHeadingText:
              'Create your Nimble account to start connecting with principals in your area.',
            continueToUrl: createButtonUrl({
              basePath: connectProfileRootUrl,
              action: gatedAction,
              schoolId: schoolId,
              jobboardState: jobboardState,
            }),
            backUrl: null,
            primaryButtonLabel: 'Continue',
            primaryButtonLabelMobile: 'Continue',
            requiredFields: ['email'],
          },
          profile: {
            step: 0,
            headerText: null,
            continueToUrl: createButtonUrl({
              basePath: connectQualificationsRootUrl,
              action: gatedAction,
              schoolId: schoolId,
              jobboardState: jobboardState,
            }),
            backUrl: null,
            primaryButtonLabel: 'Continue',
            primaryButtonLabelMobile: 'Continue',
            requiredFields: ['firstName', 'lastName', 'openToContact'],
          },
          qualifications: {
            step: 1,
            headerText: 'Tell us your qualifications',
            continueToUrl: createButtonUrl({
              basePath: connectPreferencesRootUrl,
              action: gatedAction,
              schoolId: schoolId,
              jobboardState: jobboardState,
            }),
            backUrl: createButtonUrl({
              basePath: connectProfileRootUrl,
              action: gatedAction,
              schoolId: schoolId,
              jobboardState: jobboardState,
            }),
            primaryButtonLabel: 'Continue',
            primaryButtonLabelMobile: 'Continue',
            requiredFields: [
              'resume',
              'certifications',
              'yearsOfTeachingExperience',
              'isEligibleToWorkInUS',
            ],
          },
          preferences: {
            step: 2,
            headerText: 'Your Preferences',
            continueToUrl: createButtonUrl({
              basePath: `${connectRootUrl}/${jobboardState}`,
              action: gatedAction,
              schoolId: schoolId,
            }),
            backUrl: createButtonUrl({
              basePath: connectQualificationsRootUrl,
              action: gatedAction,
              schoolId: schoolId,
              jobboardState: jobboardState,
            }),
            primaryButtonLabel: 'Save & Finish',
            primaryButtonLabelMobile: 'Finish',
            requiredFields: hasConditionalRequirements ? ['subject', 'grade', 'distance'] : [],
          },
        },
      };
      break;
  }

  return {
    flowDisplayInformation: flowDisplayInformation,
    pageDisplayInformation: getProfileFlowPage(pathname, flowDisplayInformation.screens),
  };
};

export const updateConnectFirstProfileSetupDateTime = async (user) => {
  if (!user?.profile?.connect_first_profile_setup && !user?.is_hijacked) {
    const currentTime = new Date(moment.now()).toISOString();
    await ExpressInterestAPI.updateConnectFirstProfileSetupDateTime(user.id, currentTime);
  }
};

export const buildRedirectUrl = (
  path: string,
  jobboardState: string,
  action: ConnectGatedActions,
  school?: School
) => {
  const urlParams: Record<string, string> = {
    [connectUrlParams.REDIRECT]: CONNECT_JOBBOARD_STATES[jobboardState].urlParam,
    [connectUrlParams.ACTION]: action,
  };

  if (school) urlParams[connectUrlParams.SCHOOL] = school.nces_id;

  return buildUrl(path, urlParams);
};

export const useConnectActions = () => {
  const history = useHistory();

  const redirectTo = (
    path: string,
    jobboardState: string,
    action: ConnectGatedActions,
    school?: School
  ) => {
    history.push(buildRedirectUrl(path, jobboardState, action, school));
  };

  const handleConnectUserAction = async (
    user: User | null,
    jobboardState: string = CONNECT_JOBBOARD_STATES.MISSOURI.urlParam,
    action: ConnectGatedActions,
    callbackFunction: () => Promise<void>,
    school?: School | null,
    actions?: Actions | null
  ) => {
    if (!user) {
      redirectTo(connectRedirectUrls.SIGN_UP, jobboardState, action, school);
      return false;
    }

    if (actions && user.profile.user_type !== userTypeToId.candidate) {
      return actions.setAuthenticatedAdminModalIsOpen(true);
    }

    if (!user.profile?.connect_first_profile_setup) {
      redirectTo(connectRedirectUrls.PROFILE, jobboardState, action, school);
      return false;
    }

    await callbackFunction();
  };

  return { handleConnectUserAction };
};

export const checkUserProfileCompletion = (
  user: User,
  history: History,
  jobboardState?: string,
  school?: School,
  action?: ConnectGatedActions
): boolean => {
  const formCompletionStatuses = user.preference?.nimble_connect_profile?.form_completion_statuses;
  const urlParamsToAdd = {
    [connectUrlParams.REDIRECT]: CONNECT_JOBBOARD_STATES[jobboardState].urlParam,
  };

  if (school && action) {
    urlParamsToAdd[connectUrlParams.SCHOOL] = school.nces_id;
    urlParamsToAdd[connectUrlParams.ACTION] = action;
  }

  if (!formCompletionStatuses.profile_form) {
    history.push(buildUrl(connectRedirectUrls.PROFILE, urlParamsToAdd));
    return false;
  } else if (
    !formCompletionStatuses.resume_and_credentials_form &&
    (action === ConnectGatedActions.EXPRESS_INTEREST || !action)
  ) {
    history.push(buildUrl(connectRedirectUrls.QUALIFICATIONS, urlParamsToAdd));
    return false;
  } else if (
    !formCompletionStatuses.preference_form &&
    (action === ConnectGatedActions.EXPRESS_INTEREST || !action)
  ) {
    history.push(buildUrl(connectRedirectUrls.PREFERENCES, urlParamsToAdd));
    return false;
  } else if (action === ConnectGatedActions.EXPRESS_INTEREST) {
    history.push(buildUrl(connectRedirectUrls.PROFILE, urlParamsToAdd));
    return false;
  } else {
    history.push(buildUrl(connectRedirectUrls.RETURN_TO, urlParamsToAdd));
    return true;
  }
};

export const getInitialUSVisaEligibility = (user: any): boolean => {
  return user.profile.is_eligible_to_work_in_us === null
    ? true
    : user.profile.is_eligible_to_work_in_us;
};

const calculateDaysLeft = (targetDate: string | Date): number => {
  const target = new Date(targetDate);
  const now = new Date();

  const targetTime = target.setHours(0, 0, 0, 0);
  const nowTime = now.setHours(0, 0, 0, 0);

  const timeDifference = targetTime - nowTime;
  const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  return daysLeft;
};

export const DaysLeftCounter: React.FC<{ targetDate: string }> = ({ targetDate }) => {
  const daysLeft = calculateDaysLeft(targetDate);

  if (daysLeft <= -29) {
    return null;
  }

  if (daysLeft <= 0) {
    return (
      <Box display={'flex'} flexDirection={'row'} gap={1} alignItems={'center'}>
        <Typography
          sx={{
            fontSize: nimbleTheme.typography.h3.fontSize,
            color: nimbleTheme.palette.white.main,
            fontWeight: nimbleTheme.typography.fontWeightMedium,
          }}
        >
          Matching has started! 🎉
        </Typography>
        {'    '}
        <Link
          href={null}
          data-testid={ConnectProfileFlowDataTestIds.LEARN_MORE_LINK}
          sx={{
            color: nimbleTheme.palette.white.main,
            textDecoration: 'underline',
            textUnderlineOffset: '2px',
            fontSize: nimbleTheme.typography.h3.fontSize,
            fontWeight: nimbleTheme.typography.fontWeightRegular,
            letterSpacing: '-0.01em',
            '&:hover': {
              color: nimbleTheme.palette.white.main,
              textDecoration: 'underline',
              cursor: 'pointer',
              fontWeight: nimbleTheme.typography.fontWeightMediumBold,
              letterSpacing: '-0.03em',
            },
          }}
        >
          Learn More
        </Link>
      </Box>
    );
  }

  if (daysLeft > 0) {
    return (
      <div>
        <strong>{daysLeft}</strong> days until matching starts!
      </div>
    );
  }

  return <div>Matching has started! 🎉</div>;
};

export const getBannerContent = (): React.ReactElement | null => {
  return DaysLeftCounter({ targetDate: '2025-02-01' });
};
export function getPlaceholderText(items: Option[], type: string): string {
  const itemCount = items.length;

  if (itemCount >= 2) {
    return type;
  }

  if (itemCount === 1) {
    return '';
  }

  return `Select ${type.toLowerCase()}(s)...`;
}

export const validateLocation = async (
  userLocation: string,
  distanceFromSchool: string
): Promise<string | null> => {
  const response = await GeolocationAPI.getMapUrl({
    address: userLocation,
    radius: distanceFromSchool,
  });
  console.log('response', response);

  return Array.isArray(response) && response[0] !== 200
    ? 'Please provide a valid address or zip code.'
    : null;
};
