import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { JobsDiscoveryValuePropBanner } from './JobDiscoveryValuePropBanner';
import { JobDiscoveryHeaderProps } from './types';

export const Header: React.FC<JobDiscoveryHeaderProps> = ({ isUserAuthenticated, actions }) => {
  return (
    <HeaderContainer>
      <JobsDiscoveryValuePropBanner isUserAuthenticated={isUserAuthenticated} actions={actions} />
    </HeaderContainer>
  );
};

const HeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
  justifyItems: 'center',
  alignItems: 'center',
  padding: '6px 0px 0px',
  [theme.breakpoints.down('lg')]: {
    padding: '0px 16px 0px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '20px 16px 0px',
  },
}));
