import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled, Theme, useMediaQuery, useTheme } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { SecondaryButton } from 'sharedComponents/Buttons';
import { Vacancy } from 'types/connectTypes';
import { ExpressInterestButton } from '../Buttons/ExpressInterestButton';
import { JobDetailsHighlightRow } from '../HighlightRows/HighlightRowJobDetails';
import { VacanciesSectionProps } from '../types';
import { ReadyToConnectModal } from './ReadyToConnectModal';
import { NoVacanciesSection, VacancyRow } from './VacancyRow';

import { makeAllUrlsValid } from 'utils/util';

import { Alert } from 'sharedComponents/Alert';
import { CONNECT_ACTION_LABEL } from 'features/Connect/features/JobDiscovery/constants';

const compareVacancyIds = (idOne: string | number, idTwo: string | number) => {
  return String(idOne) === String(idTwo);
};

export const VacancyAccordionRow: React.FC<VacanciesSectionProps> = ({
  vacancies,
  vacancyId,
  school,
  actions,
  isReadyToConnectModalOpen,
}) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const theme = useTheme();

  const handleLinkToFormalApplicationClick = (): React.ReactElement | void => {
    if (!vacancyId) {
      console.error('No vacancy ID found for link to formal application');
      return;
    }

    actions.setReadyToConnectModalIsOpen(true);
  };

  const handleCloseModal = () => {
    actions.setReadyToConnectModalIsOpen(false);
    actions.setSelectedVacancyId('0');
  };

  const handleChange =
    (vacancyId: string) => (_event: React.SyntheticEvent, newExpanded: boolean) => {
      actions.setSelectedVacancyId(newExpanded ? vacancyId : '0');
    };

  const shouldRenderLinkToFormalApplicationButton = (vacancy: Vacancy): boolean => {
    if (!vacancyId || vacancyId === '0') return false;
    return vacancy && vacancy.job_url && vacancy.job_url !== '';
  };

  const parseAndRenderJobDescription = (vacancy: Vacancy): React.ReactElement | null => {
    if (!vacancy.job_description) return null;

    let renderedContent = null;
    if (vacancy?.nimble_id || vacancy?.source === 'nimble') {
      renderedContent = {
        __html: makeAllUrlsValid(vacancy?.job_description),
      };
      return (
        <Box
          key={`description-${vacancyId}`}
          component="div"
          padding="12px"
          dangerouslySetInnerHTML={renderedContent}
        />
      );
    } else {
      const paragraphs = vacancy?.job_description.split('\n\n');
      renderedContent = paragraphs.map((paragraph, paragraphIndex) => {
        const lines = paragraph.split('\n');
        return (
          <Typography key={`paragraph-${paragraphIndex}`}>
            {lines.map((line, lineIndex) => (
              <React.Fragment key={`line-${paragraphIndex}-${lineIndex}`}>
                {line}
                {lineIndex < lines.length - 1 && <br />}
              </React.Fragment>
            ))}
          </Typography>
        );
      });
      return (
        <Box key={`description-${vacancyId}`} component="div" padding="12px">
          {renderedContent}
        </Box>
      );
    }
  };

  if (!vacancies || vacancies?.length === 0) {
    return <NoVacanciesSection />;
  }

  return (
    <Stack direction={'column'} width={'100%'}>
      <Typography sx={{ marginBottom: '16px' }}>{vacancies?.length} Job Openings</Typography>
      {vacancies?.map((vacancy: Vacancy) => {
        return (
          <VacancyAccordion
            key={vacancy?.uuid}
            TransitionProps={{ unmountOnExit: true }}
            expanded={compareVacancyIds(vacancy?.uuid, vacancyId as string)}
            onChange={handleChange(vacancy?.uuid)}
          >
            <VacancyAccordionSummary
              expandIcon={
                <ExpandMoreIcon sx={{ width: '20px', height: '20px', fontSize: '20px' }} />
              }
            >
              <VacancyRow vacancy={vacancy} />
            </VacancyAccordionSummary>
            <VacancyAccordionDetails>
              <Stack spacing={1} marginTop={1}>
                {vacancy?.pooled_posting_type === 'pooled_posting' && (
                  <Alert>
                    {`A district-wide posting may have one or more openings across multiple schools.
                    Click "${CONNECT_ACTION_LABEL}" to connect with the principal and confirm
                    this role.`}
                  </Alert>
                )}
                <LabelText>Job Details</LabelText>
                <JobDetailsHighlightRow vacancy={vacancy} />
                <LabelText>Job Summary</LabelText>
                {parseAndRenderJobDescription(vacancy)}
                <Stack direction={isMobile ? 'column' : 'row'} spacing={1}>
                  <ExpressInterestButton
                    school={school}
                    actions={actions}
                    sx={{
                      width: isMobile ? '100%' : 'auto',
                    }}
                  />
                  {shouldRenderLinkToFormalApplicationButton(vacancy) && (
                    <SecondaryButton
                      onClick={handleLinkToFormalApplicationClick}
                      size="small"
                      sx={{ ...buttonStyles(theme, isMobile) }}
                    >
                      Link to formal application
                    </SecondaryButton>
                  )}
                </Stack>
              </Stack>
            </VacancyAccordionDetails>
            {isReadyToConnectModalOpen && (
              <ReadyToConnectModal
                isOpen={isReadyToConnectModalOpen}
                handleClose={handleCloseModal}
                school={school}
                vacancy={vacancy}
                actions={actions}
              />
            )}
          </VacancyAccordion>
        );
      })}
    </Stack>
  );
};

const VacancyAccordion = styled(Accordion)(({ theme }) => ({
  margin: '8px 0px',
  border: 'none',
  borderRadius: '8px',
  boxShadow: 'none',
  width: '100%',

  '&.Mui-expanded': {
    margin: 0,
    padding: '16px',
    border: '1px solid #EAECF0',
    borderRadius: '8px',
    '& .MuiAccordionSummary-root': {
      border: `1px solid ${theme.palette.text.secondary}`,
      borderRadius: '8px',
      backgroundColor: theme.palette.white.medium,
    },
  },

  '&::before': {
    backgroundColor: 'transparent',
  },
}));

const VacancyAccordionSummary = styled(AccordionSummary)(() => ({
  margin: 0,
  justifyContent: 'space-between',
  textAlign: 'left',
  border: '1px solid #EAECF0',
  borderRadius: '8px',
  width: '100%',
  padding: '0px 12px 0px 0px',
  '& .MuiAccordionSummary-content': { margin: 0 },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: 0,
  },
}));

const VacancyAccordionDetails = styled(AccordionDetails)(() => ({
  width: '100%',
  padding: 0,
}));

const LabelText = styled(Typography)(() => ({
  fontSize: '14px',
  fontWeight: '600',
  color: '#344054',
}));

const buttonStyles = (theme: Theme, isMobile: boolean) => ({
  color: theme.palette.text.secondary,
  border: `1px solid ${theme.palette.gray.mediumLight}`,
  borderRadius: theme.shape.borderRadius * 0.5,
  padding: '8px 14px',
  width: isMobile ? '100%' : 'auto',
  fontSize: theme.typography.fontSize,
  fontWeight: theme.typography.fontWeightMediumBold,
  '&:hover': {
    backgroundColor: theme.palette.white.medium,
    border: `1px solid ${theme.palette.text.secondary}`,
  },
  '& .MuiTouchRipple-root': {
    color: theme.palette.gray.mediumLight,
  },
});
