import { Divider, Tabs } from '@mui/material';

import { ATSTabbedJobViewNavigationTestIds } from 'data-testids/ATS';
import { CustomTab } from './styles';
import { DesktopNavigationProps } from '../types';

export const DesktopNavigation: React.FC<DesktopNavigationProps> = ({
  bottomTabsData,
  onChange,
  selectedTab,
  shouldShowDivider,
  topTabsData,
}) => {
  return (
    <Tabs
      data-testid={ATSTabbedJobViewNavigationTestIds.DESKTOP_NAVIGATION}
      onChange={onChange}
      orientation="vertical"
      value={selectedTab}
    >
      {topTabsData.map((tab) => (
        <CustomTab
          data-testid={`${ATSTabbedJobViewNavigationTestIds.DESKTOP_TAB}-${tab.value}`}
          key={tab.value}
          label={tab.label}
          sx={{ display: tab.isVisible ? 'block' : 'none' }}
          value={tab.value}
        />
      ))}
      <Divider sx={{ display: shouldShowDivider ? 'block' : 'none' }} />
      {bottomTabsData.map((tab) => (
        <CustomTab
          data-testid={`${ATSTabbedJobViewNavigationTestIds.DESKTOP_TAB}-${tab.value}`}
          key={tab.value}
          label={tab.label}
          sx={{ display: tab.isVisible ? 'block' : 'none' }}
          value={tab.value}
        />
      ))}
    </Tabs>
  );
};
