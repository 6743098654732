import { PrimaryButton } from 'sharedComponents/Buttons';
import { useTheme } from '@mui/material/styles';
import { ConnectMarketingMessageAndButtonProps } from '../types';
import { ConnectMarketingInputAndButtonDataTestIds } from 'data-testids/ConnectDataTestIds';
import auth from 'utils/auth';
import { useHistory } from 'react-router';
import { checkUserProfileCompletion } from 'features/Connect/features/ExpressInterest/utils';
import {
  connectRedirectUrls,
  connectUrlParams,
} from 'features/Connect/features/JobDiscovery/constants';
import { buildUrl } from 'utils/util';
import { useStateParam } from 'features/Connect/features/ConnectStateCodeContextProvider';
import { CONNECT_JOBBOARD_STATES } from 'utils/constants';
import { userTypeToId } from 'utils/typedEnums';

export function ConnectMarketingMessageAndButton({
  isLoading,
  actions,
}: ConnectMarketingMessageAndButtonProps): React.ReactElement {
  const stateCode = useStateParam();
  const theme = useTheme();
  const user = auth.getUser();
  const history = useHistory();

  const onClickHandler = () => {
    if (!user) {
      history.push(
        buildUrl(connectRedirectUrls.SIGN_UP, {
          [connectUrlParams.REDIRECT]: CONNECT_JOBBOARD_STATES[stateCode].urlParam,
        })
      );
    } else if (user?.profile?.user_type !== userTypeToId.candidate) {
      actions?.setAuthenticatedAdminModalIsOpen(true);
    } else if (!user?.preference?.nimble_connect_profile?.is_completed) {
      checkUserProfileCompletion(user, history, stateCode);
    } else {
      history.push(
        buildUrl(connectRedirectUrls.PROFILE, {
          [connectUrlParams.REDIRECT]: CONNECT_JOBBOARD_STATES[stateCode].urlParam,
        })
      );
    }
  };

  return (
    <PrimaryButton
      size="small"
      isLoading={isLoading}
      dataTestId={ConnectMarketingInputAndButtonDataTestIds.COMPLETE_PROFILE_BUTTON}
      sx={primaryButtonStyles(theme)}
      onClick={onClickHandler}
    >
      Complete your profile now
    </PrimaryButton>
  );
}

const primaryButtonStyles = (theme) => ({
  display: 'flex',
  maxWidth: '275px',
  borderRadius: '16px',
  padding: '15px 32px',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
  gap: '8px',
  fontSize: '16px',
  fontWeight: '700',
  textWrap: 'nowrap',
  [theme.breakpoints.down('md')]: {
    marginLeft: 'auto !important',
    marginRight: 'auto !important',
  },
});
