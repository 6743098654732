import { ReactNode } from 'react';

import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary,
  styled,
  SxProps,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { nimbleTheme } from 'theme';

export interface AccordionProps {
  children: ReactNode;
  defaultExpanded?: boolean;
  disabled?: boolean;
  title: string | ReactNode;
  dataTestId: string;
  sx?: SxProps;
  summarySx?: SxProps;
}

export const Accordion: React.FC<AccordionProps> = ({
  children,
  defaultExpanded,
  disabled,
  title,
  dataTestId,
  sx,
  summarySx,
}) => {
  return (
    <MuiAccordion
      data-testid={dataTestId}
      defaultExpanded={defaultExpanded}
      disabled={disabled}
      sx={{ ...sx, boxShadow: 'none' }}
    >
      <StyledAccordionSummary
        expandIcon={<ExpandMoreIcon data-testid="expansion-icon" />}
        data-testid="accordion-title"
        sx={summarySx}
      >
        {title}
      </StyledAccordionSummary>
      <StyledAccordionDetails data-testid="accordion-details">{children}</StyledAccordionDetails>
    </MuiAccordion>
  );
};

const StyledAccordionDetails = styled(AccordionDetails)({
  color: nimbleTheme.palette.text.light,
  lineHeight: nimbleTheme.spacing(3),
});

const StyledAccordionSummary = styled(AccordionSummary)({
  fontWeight: 500,
  lineHeight: nimbleTheme.spacing(3),
});
