import { PrimaryButton, SecondaryButton } from 'sharedComponents/Buttons';
import { BlankModal } from 'sharedComponents/Modal';
import { Actions } from 'types/connectTypes';
import { Stack, Theme, Typography, useMediaQuery } from '@mui/material';

export interface ExpressInterestExitModalProps {
  actions: Actions;
  isOpen: boolean;
}

export const ExpressInterestExitModal: React.FC<ExpressInterestExitModalProps> = ({
  isOpen = true,
  actions,
}) => {
  const isMobile = useMediaQuery(
    (theme: Theme) =>
      theme.breakpoints.down('md') || theme.breakpoints.down('sm') || theme.breakpoints.down('xs')
  );

  const exitButtonHandler = () => {
    actions.setExpressInterestExitModalIsOpen(false);
  };

  const handleCloseModal = (_?: unknown, reason?: string) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;

    actions.setExpressInterestExitModalIsOpen(false);
  };

  return (
    <BlankModal
      open={isOpen}
      onClose={handleCloseModal}
      header={
        <Typography
          sx={{
            color: '#101828',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 600,
            padding: '0px 0px 12px',
          }}
        >
          Are you sure you want to leave?
        </Typography>
      }
      body={
        <>
          <Typography sx={{ marginBottom: '24px' }}>
            If you exit, expressing interest in this school will not be saved or sent to the
            principal.
          </Typography>
          <Stack direction="row" justifyContent="flex-end">
            <SecondaryButton
              sx={{
                border: '1px solid #D0D5DD',
                borderRadius: '8px',
                color: '#344054',
                fontSize: isMobile ? '14px' : '16px',
                fontWeight: 600,
                marginRight: '12px',
              }}
              onClick={() => actions.setExpressInterestModalIsOpen(true)}
              size="large"
            >
              No, Stay
            </SecondaryButton>
            <PrimaryButton
              onClick={exitButtonHandler}
              size="large"
              sx={{
                fontWeight: 600,
                fontSize: isMobile ? '14px' : '16px',
                borderRadius: '8px',
              }}
            >
              Yes, Exit
            </PrimaryButton>
          </Stack>
        </>
      }
      sx={{ borderRadius: '12px' }}
    />
  );
};
