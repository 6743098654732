import styled from 'styled-components';
import { grid } from 'styled-system';

import { AltModal } from 'ui-kit';
import { scorecardQType } from '../../utils/enums';
import auth from 'utils/auth';

import TextViewAnswer from '../ScorecardModal/ViewerAnswerTypes/TextViewAnswer';
import MultipleChoiceViewAnswer from '../ScorecardModal/ViewerAnswerTypes/MultipleChoiceViewAnswer';
import RubricViewAnswer from '../ScorecardModal/ViewerAnswerTypes/RubricViewAnswer';
import CumulativeScoreViewAnswer from '../ScorecardModal/ViewerAnswerTypes/CumulativeScoreViewAnswer';
import FinalRecommendationViewAnswer from '../ScorecardModal/ViewerAnswerTypes/FinalRecommendationViewAnswer';
import DirectionsViewAnswer from '../ScorecardModal/ViewerAnswerTypes/DirectionsViewAnswer';
import RatingViewAnswer from '../ScorecardModal/ViewerAnswerTypes/RatingViewAnswer';
import AttachmentViewAnswer from '../ScorecardModal/ViewerAnswerTypes/AttachmentViewAnswer';
import PrintIcon from '@mui/icons-material/Print';
import { ReactComponent as GrayArrow } from 'assets/icon_right_caret_grey.svg';
import { ReferenceViewerProps } from './types';
import { TooltipIconButton } from 'sharedComponents/Buttons/TooltipIconButton';
import { useHistory, useLocation } from 'react-router';
import { nimbleTheme } from 'theme';
import { ATSCandidateReferencesDataTestIds } from 'data-testids/ATS';
import { Answer } from 'types';

const extractUserIdFromPath = (path: string): string | null => {
  // Use the same RegExp to match the last number in the path
  const match = path.match(/(\d+)\/?$/);
  return match ? match[1] : null;
};

const ReferenceViewer: React.FC<ReferenceViewerProps> = ({ onBack, onEdit, referenceCheck }) => {
  const history = useHistory();
  const renderQuestion = (item: Answer) => {
    if (
      item.question_type === scorecardQType.rubric ||
      item.question_type === scorecardQType.direction_text
    ) {
      return null;
    }

    let question = '';
    if (item.question_type === scorecardQType.cumulative_score) {
      question = 'Cumulative score:';
    } else if (item.question_type === scorecardQType.final_recommendation) {
      question = 'Do you recommend this candidate?';
    } else {
      question = item.question_text;
    }

    return (
      <>
        <QuestionText>{question}</QuestionText>
        {item.is_required && <sup>*</sup>}
      </>
    );
  };

  const renderAnswer = (answer: Answer) => {
    switch (answer.question_type) {
      case scorecardQType.rating:
        return <RatingViewAnswer answer={answer} />;
      case scorecardQType.nimble:
      case scorecardQType.text:
        return <TextViewAnswer answer={answer} />;
      case scorecardQType.multiple_choice:
        return <MultipleChoiceViewAnswer answer={answer} />;
      case scorecardQType.cumulative_score:
        return <CumulativeScoreViewAnswer parentObject={referenceCheck} />;
      case scorecardQType.direction_text:
        return <DirectionsViewAnswer answer={answer} />;
      case scorecardQType.final_recommendation:
        return <FinalRecommendationViewAnswer answer={answer} parentObject={referenceCheck} />;
      case scorecardQType.rubric:
        return <RubricViewAnswer answer={answer} />;
      case scorecardQType.attachment:
        return <AttachmentViewAnswer answer={answer} urlBase="reference_check_answer" />;
      default:
        return <div />;
    }
  };
  const location = useLocation();
  const userId = extractUserIdFromPath(location.pathname);
  const applicationSubstring = location.search.split('=')[1];

  const getPrintReferenceURL = () => {
    if (location.pathname.includes('/school-profile/')) {
      return `/profile/${userId}/reference/${referenceCheck.id}/?schoolapplication=${applicationSubstring}`;
    } else {
      return `/profile/${userId}/reference/${referenceCheck.id}/?application=${applicationSubstring}`;
    }
  };

  return (
    <>
      <ModalBody>
        <BackButtonContainer onClick={onBack}>
          <BackButtonIcon />
          Return to reference summary
        </BackButtonContainer>
        <HeaderText>
          {referenceCheck.template_name}
          <TooltipIconButton
            tooltipTitle="Print reference"
            onClick={() => history.push(getPrintReferenceURL())}
            dataTestId={ATSCandidateReferencesDataTestIds.PRINT}
          >
            <PrintIcon />
          </TooltipIconButton>
        </HeaderText>
        {referenceCheck.answers.map((item) => (
          <AnswerItem key={item.id}>
            {renderQuestion(item)}
            {renderAnswer(item)}
          </AnswerItem>
        ))}
      </ModalBody>
      <FooterContainer>
        {(auth.isSuperAdminUser() || auth.getUser().email === referenceCheck.submitted_by) && (
          <EditButton onClick={() => onEdit(referenceCheck)}>Edit Reference</EditButton>
        )}
        <SubmitButton onClick={onBack}>Done</SubmitButton>
      </FooterContainer>
    </>
  );
};

export default ReferenceViewer;

const ModalBody = styled(AltModal.Body)`
  position: relative;
`;

const HeaderText = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;

  color: #444444;

  margin-bottom: ${nimbleTheme.spacing(1.5)};
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
`;

const QuestionText = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;

  color: #444444;
`;

const BackButtonContainer = styled.div`
  color: var(--darkgray) !important;
  font-size: 16px !important;
  cursor: pointer;
  display: flex;
  align-items: center;

  width: fit-content;
  position: absolute;
  top: 20px;
`;

const BackButtonIcon = styled(GrayArrow)`
  transform: rotate(180deg);
  margin-right: 7px;
`;

const AnswerItem = styled.div`
  margin-bottom: 50px;
`;

const FooterContainer = styled(AltModal.Actions)(
  {
    display: 'grid',
    gridGap: '16px',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '16px',
  },
  grid
);

const FooterButton = styled.button({
  height: '50px',
  borderRadius: '3px',
  width: '100%',
});

const EditButton = styled(FooterButton)({
  backgroundColor: '#FFFFFF',
  border: '1px solid #CACACA',
  color: '#CACACA',
});

const SubmitButton = styled(FooterButton)`
  background: #00b88d;
  border: none;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;

  color: #ffffff;
`;
