import styled from 'styled-components';
import { grid } from 'styled-system';

import { AltModal } from 'ui-kit';
import { scorecardQType } from 'utils/enums';

import TextViewAnswer from 'components/ScorecardModal/ViewerAnswerTypes/TextViewAnswer';
import MultipleChoiceViewAnswer from 'components/ScorecardModal/ViewerAnswerTypes/MultipleChoiceViewAnswer';
import RubricViewAnswer from 'components/ScorecardModal/ViewerAnswerTypes/RubricViewAnswer';
import CumulativeScoreViewAnswer from 'components/ScorecardModal/ViewerAnswerTypes/CumulativeScoreViewAnswer';
import FinalRecommendationViewAnswer from 'components/ScorecardModal/ViewerAnswerTypes/FinalRecommendationViewAnswer';
import DirectionsViewAnswer from 'components/ScorecardModal/ViewerAnswerTypes/DirectionsViewAnswer';
import RatingViewAnswer from 'components/ScorecardModal/ViewerAnswerTypes/RatingViewAnswer';
import AttachmentViewAnswer from 'components/ScorecardModal/ViewerAnswerTypes/AttachmentViewAnswer';
import { ReferenceViewerActivityFeedProps } from './types';
import { ReferenceCheckAnswer } from 'types';

export const ReferenceViewerActivityFeed: React.FC<ReferenceViewerActivityFeedProps> = ({
  onBack,
  referenceCheck,
}) => {
  const renderQuestion = (item: ReferenceCheckAnswer): JSX.Element | null => {
    if (
      item.question_type === scorecardQType.rubric ||
      item.question_type === scorecardQType.direction_text
    ) {
      return null;
    }

    let question = '';
    if (item.question_type === scorecardQType.cumulative_score) {
      question = 'Cumulative score:';
    } else if (item.question_type === scorecardQType.final_recommendation) {
      question = 'Do you recommend this candidate?';
    } else {
      question = item.question_text;
    }

    return (
      <>
        <QuestionText>{question}</QuestionText>
        {item.is_required && <sup>*</sup>}
      </>
    );
  };

  const renderAnswer = (answer: ReferenceCheckAnswer): JSX.Element => {
    switch (answer.question_type) {
      case scorecardQType.rating:
        return <RatingViewAnswer answer={answer} />;
      case scorecardQType.nimble:
      case scorecardQType.text:
        return <TextViewAnswer answer={answer} />;
      case scorecardQType.multiple_choice:
        return <MultipleChoiceViewAnswer answer={answer} />;
      case scorecardQType.cumulative_score:
        return <CumulativeScoreViewAnswer parentObject={referenceCheck} />;
      case scorecardQType.direction_text:
        return <DirectionsViewAnswer answer={answer} />;
      case scorecardQType.final_recommendation:
        return <FinalRecommendationViewAnswer answer={answer} parentObject={referenceCheck} />;
      case scorecardQType.rubric:
        return <RubricViewAnswer answer={answer} />;
      case scorecardQType.attachment:
        return <AttachmentViewAnswer answer={answer} urlBase="reference_check_answer" />;
      default:
        return <div />;
    }
  };

  return (
    <>
      <ModalBody>
        <HeaderText>{referenceCheck.template_name}</HeaderText>
        {referenceCheck.answers.map((item) => (
          <AnswerItem key={item.id}>
            {renderQuestion(item)}
            {renderAnswer(item)}
          </AnswerItem>
        ))}
      </ModalBody>
      <FooterContainer>
        <SubmitButton onClick={onBack}>Done</SubmitButton>
      </FooterContainer>
    </>
  );
};

const ModalBody = styled(AltModal.Body)`
  position: relative;
`;

const HeaderText = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;

  color: #444444;

  margin-bottom: 13px;
`;

const QuestionText = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;

  color: #444444;
`;

const AnswerItem = styled.div`
  margin-bottom: 50px;
`;

const FooterContainer = styled(AltModal.Actions)(
  {
    display: 'grid',
    gridGap: '16px',
    justifyContent: 'space-between',
    alignItems: 'center',

    fontSize: '16px',
  },
  grid
);

const FooterButton = styled.button({
  height: '50px',
  borderRadius: '3px',

  width: '100%',
});

const SubmitButton = styled(FooterButton)`
  background: #00b88d;
  border: none;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;

  color: #ffffff;
`;
