import { useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Skeleton, Theme } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { JobDiscoveryFilterContainerProps } from 'types/connectTypes';
import { JobDiscoveryLocationFilter } from './JobDiscoveryLocationFilter';
import { Filters } from './JobDiscoveryFilters';
import { ConnectFilterName } from 'features/Connect/utils/connectEnums';
import { SearchbarMultiSelect } from 'sharedComponents/Select/SearchbarMultiSelect';
import { ConnectJobDiscoveryFiltersDataTestIds } from 'data-testids/ConnectDataTestIds';
import { useStateParam } from '../../ConnectStateCodeContextProvider';
import { CONNECT_JOBBOARD_STATES } from 'utils/constants/connect';

import { mapOptionsToValues } from 'sharedComponents/Select';

export const JobDiscoveryFilterContainer: React.FC<JobDiscoveryFilterContainerProps> = ({
  filterValues,
  actions,
  filterOptions,
  isLoading,
  activeFilters,
}) => {
  const jobboardState = useStateParam();

  const [isLoadingMap, setIsLoadingMap] = useState<boolean>(false);
  const [mapUrl, setMapUrl] = useState<string>(
    CONNECT_JOBBOARD_STATES[jobboardState]?.defaultMapUrl || ''
  );

  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.between('xs', 'lg'));

  const getSelectedOptions = (selectedOptions) => {
    if (!Array.isArray(selectedOptions)) {
      return [];
    }
    return selectedOptions.map((option) => option.value);
  };

  const updateSelectedFilterOptions = (
    selectedValues,
    options,
    updateSelectedValues,
    setFilterIsActive,
    filtername
  ) => {
    // ignore events until options is loaded
    if (filterOptions.isLoadingFilterOptions) {
      return;
    }

    actions.setIsLoadingInitialFilterResults(true);
    const selectedOptions = getSelectedOptions(selectedValues);

    if (selectedValues?.length === options?.length || selectedValues?.length === 0) {
      updateSelectedValues([]);
      setFilterIsActive(filtername, false);
    } else {
      updateSelectedValues(selectedOptions);
      setFilterIsActive(filtername, true);
    }
  };

  const setSelectedSubjectOptions = (selectedSubjectOptions) => {
    updateSelectedFilterOptions(
      selectedSubjectOptions,
      filterOptions.subjectOptions,
      actions.setSelectedSubjectIds,
      actions.setFilterIsActive,
      ConnectFilterName.SUBCATEGORIES
    );
  };

  const setSelectedGradeOptions = (selectedGradeOptions) => {
    updateSelectedFilterOptions(
      selectedGradeOptions,
      filterOptions.gradeOptions,
      actions.setSelectedGrades,
      actions.setFilterIsActive,
      ConnectFilterName.GRADES
    );
  };

  const setSelectedSchoolOptions = (selectedSchoolOptions) => {
    updateSelectedFilterOptions(
      selectedSchoolOptions,
      filterOptions.schoolOptions,
      actions.setSelectedSchoolIds,
      actions.setFilterIsActive,
      ConnectFilterName.SCHOOLS
    );
  };

  const setSelectedDistrictOptions = (selectedDistrictOptions) => {
    updateSelectedFilterOptions(
      selectedDistrictOptions,
      filterOptions.districtOptions,
      actions.setSelectedDistrictIds,
      actions.setFilterIsActive,
      ConnectFilterName.DISTRICTS
    );
  };

  const schoolValues = useMemo(() => {
    return mapOptionsToValues(filterOptions.schoolOptions, filterValues.school_nces_ids);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filterValues.school_nces_ids), filterOptions.schoolOptions.length]);

  return (
    <FilterContainer>
      <MapContainer sx={{ display: { md: 'none' } }}>
        {isLoadingMap ? (
          <MapLoadingContainer>
            <Skeleton
              variant="rounded"
              width="100%"
              height="148px"
              animation="wave"
              sx={{ animationDuration: '0.4s', bgcolor: 'grey.100' }}
            />
          </MapLoadingContainer>
        ) : (
          <Map src={mapUrl} alt="locations" />
        )}
      </MapContainer>
      <Grid container spacing={1.5} justifyContent={'space-between'}>
        <Grid
          item
          sx={{ display: { xs: 'block', md: 'none', lg: 'block' } }}
          xs={12}
          sm={12}
          lg={5}
        >
          <SearchbarMultiSelect
            displayName="School search"
            size="small"
            placeholder="Search for school"
            options={filterOptions.schoolOptions}
            values={schoolValues}
            handleChange={setSelectedSchoolOptions}
            dataTestId={ConnectJobDiscoveryFiltersDataTestIds.SCHOOL_SEARCH_FILTER}
            sx={InputStyles(theme)}
            hasSubLabel={true}
            subLabelKeys={['address.city']}
          />
        </Grid>
        <Grid item sx={{ display: { xs: 'block', md: 'none' } }} xs={12}>
          <Filters
            filterValues={filterValues}
            filterOptions={filterOptions}
            isMobile={isMobile}
            setSelectedGradeOptions={setSelectedGradeOptions}
            setSelectedSubjectOptions={setSelectedSubjectOptions}
            setSelectedDistrictOptions={setSelectedDistrictOptions}
            activeFilters={activeFilters}
          />
        </Grid>
        <Grid item xs={12} lg={5}>
          <JobDiscoveryLocationFilter
            distanceDisplayName="Distance"
            locationDisplayName="Enter address or zip code"
            filterValues={filterValues}
            locationChangeHandler={actions.setSelectedLocation}
            filterOptions={filterOptions.distanceOptions}
            actions={actions}
            setMapUrl={setMapUrl}
            setIsLoadingDefaultMap={setIsLoadingMap}
            mapUrl={mapUrl}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
      <MapContainer sx={{ display: { xs: 'none', md: 'flex' } }}>
        {isLoadingMap ? (
          <MapLoadingContainer>
            <Skeleton
              variant="rounded"
              width="100%"
              height="224px"
              animation="wave"
              sx={{ animationDuration: '0.4s', bgcolor: 'grey.100' }}
            />
          </MapLoadingContainer>
        ) : (
          <Map src={mapUrl} alt="locations" />
        )}
      </MapContainer>
      <Grid container spacing={1.5} justifyContent={'space-between'}>
        <Grid item sx={{ display: { xs: 'none', md: 'block', lg: 'none' } }} xs={12} sm={12} lg={5}>
          <SearchbarMultiSelect
            displayName="School search"
            size="small"
            placeholder="Search for school"
            options={filterOptions.schoolOptions}
            values={schoolValues}
            handleChange={setSelectedSchoolOptions}
            dataTestId={ConnectJobDiscoveryFiltersDataTestIds.SCHOOL_SEARCH_FILTER}
            sx={InputStyles(theme)}
            hasSubLabel={true}
            subLabelKeys={['address.city']}
          />
        </Grid>
        <Grid
          item
          sx={{ display: { xs: 'none', md: 'block' } }}
          lg={9}
          md={12}
          padding={`${theme.spacing(0.5)} 0`}
        >
          <Filters
            filterValues={filterValues}
            filterOptions={filterOptions}
            isMobile={isMobile}
            setSelectedGradeOptions={setSelectedGradeOptions}
            setSelectedSubjectOptions={setSelectedSubjectOptions}
            setSelectedDistrictOptions={setSelectedDistrictOptions}
            activeFilters={activeFilters}
          />
        </Grid>
      </Grid>
    </FilterContainer>
  );
};

const FilterContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    margin: `0px 0px ${theme.spacing(3)}`,
  },
}));

const MapContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  border: `solid 1px ${theme.palette.gray.darker}`,
  borderRadius: theme.shape.borderRadius * 3,
  margin: `${theme.spacing(2)} 0px`,
  [theme.breakpoints.down('md')]: {
    height: '148px',
    margin: `${theme.spacing(3)} 0px`,
  },
  overflow: 'hidden',
}));

const Map = styled('img')(({ theme }) => ({
  background: 'url(${props => props.src}) lightgray 50% / cover no-repeat',
  alignSelf: 'center',
  backgroundPosition: 'center',
  width: '100%',
  height: '224px',
  borderRadius: theme.shape.borderRadius * 3,
  border: `solid 1px ${theme.palette.gray.darker}`,
  objectFit: 'cover',
  [theme.breakpoints.down('md')]: {
    height: '100%',
  },
}));

const MapLoadingContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
}));

export const InputStyles = (theme: Theme) => ({
  width: '100%',
  fontSize: theme.typography.subtitle.fontSize,
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.shape.borderRadius * 0.5,
  },
  '& .MuiInputLabel-root': {
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.body1.fontSize,
    },
  },
  '& .MuiInputBase-input': {
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.subtitle.fontSize,
    },
  },
});
