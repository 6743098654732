import { JobPostingTab } from '../JobPostingTab/JobPostingTab';
import { JobViewTabData } from './types';
import { QuestionsTab } from '../QuestionsTab/QuestionsTab';
import { SetUpTab } from '../SetUpTab/SetUpTab';
import auth from 'utils/auth';
import HiringTeamTab from '../HiringTeamTab';
import InternalNotesTab from '../InternalNotesTab';
import VacanciesTab from '../VacanciesTab/VacanciesTab';

export const getTopTabsData = (jobId: number): JobViewTabData[] => [
  { value: 0, label: 'Job Posting', content: <JobPostingTab jobId={jobId} />, isVisible: true },
  { value: 1, label: 'Questions', content: <QuestionsTab jobId={jobId} />, isVisible: false },
  {
    value: 2,
    label: 'Vacancies',
    content: <VacanciesTab jobId={jobId} />,
    isVisible: auth.isDistrictAdmin(),
  },
  { value: 3, label: 'Job Set-up', content: <SetUpTab jobId={jobId} />, isVisible: true },
  { value: 4, label: 'Hiring Team', content: <HiringTeamTab jobId={jobId} />, isVisible: true },
];

export const getBottomTabsData = (jobId: number): JobViewTabData[] => [
  {
    value: 5,
    label: 'Internal Notes',
    content: <InternalNotesTab jobId={jobId} />,
    isVisible: true,
  },
];
