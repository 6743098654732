import { Typography, Grid, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import lazyRegistrationAPI from 'api/lazyRegistrationAPI';
import { ConnectMarketingInputAndButton } from 'features/Connect/components/ConnectMarketingInputAndButton/ConnectMarketingInputAndButton';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { nimbleProduct } from 'types/types';
import auth from 'utils/auth';
import { isValidEmail } from 'utils/emailutils';
import { MagicLinkModal } from '../ConnectSignUpLogin/MagicLinkModal';
import { JobsDiscoveryValuePropBannerProps } from './types';
import { ConnectMarketingMessageAndButton } from 'features/Connect/components/ConnectMarketingInputAndButton/ConnectMarketingMessageAndButton';
import { ConnectGatedActions } from 'features/Connect/utils/connectEnums';
import { connectRedirectUrls, connectUrlParams } from './constants';
import { useStateParam } from '../ConnectStateCodeContextProvider';
import { buildUrl } from 'utils/util';
import { ThemeProvider } from '@mui/material';
import { getTheme } from 'stateThemes';
import { CONNECT_JOBBOARD_STATES } from 'utils/constants';
import { ConnectValuePropBannerDataTestIds } from 'data-testids/ConnectDataTestIds';

export const JobsDiscoveryValuePropBanner: React.FC<JobsDiscoveryValuePropBannerProps> = ({
  isUserAuthenticated,
  actions,
}) => {
  const stateCode = useStateParam();
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [magicLinkModalOpen, setMagicLinkModalOpen] = useState(false);
  const [submitButtonActive, setSubmitButtonActive] = useState(true);
  const history = useHistory();
  const stateTheme = getTheme(stateCode);

  const getValuePropBannerGraphic = () => {
    return CONNECT_JOBBOARD_STATES[stateCode].valuePropBannerGraphic;
  };

  useEffect(() => {
    if (email !== '') {
      const emailValid = isValidEmail(email);
      setSubmitButtonActive(emailValid);
    } else {
      setSubmitButtonActive(true);
    }
  }, [email]);

  const redirectUrl = buildUrl(connectRedirectUrls.PROFILE, {
    [connectUrlParams.REDIRECT]: CONNECT_JOBBOARD_STATES[stateCode].urlParam,
  });

  const handleSubmit = async () => {
    if (email === '') return;

    setIsLoading(true);
    const userData = await lazyRegistrationAPI.registerUser(
      email,
      true,
      nimbleProduct.connect,
      redirectUrl,
      CONNECT_JOBBOARD_STATES[stateCode].urlParam,
      ConnectGatedActions.SIGNUP_BANNER
    );
    setIsLoading(false);

    if (userData?.login_required) {
      setMagicLinkModalOpen(true);
    } else {
      auth.setUser(userData);
      history.push(redirectUrl);
    }
  };

  return (
    <ThemeProvider theme={stateTheme}>
      <ValuePropBannerContainer
        container
        spacing={{ xs: 2, md: 3, lg: 3 }}
        isAuthenticated={isUserAuthenticated}
      >
        {magicLinkModalOpen && (
          <MagicLinkModal
            email={email}
            isOpen={magicLinkModalOpen}
            closeModal={() => setMagicLinkModalOpen(false)}
            expiredModal={false}
            currentPath={connectRedirectUrls.RETURN_TO}
            redirectState={CONNECT_JOBBOARD_STATES[stateCode].urlParam}
          />
        )}
        <HeaderAndInputContainer container item xs={12} lg={6.5}>
          <HeaderContainer container item>
            <HeaderText data-testid={ConnectValuePropBannerDataTestIds.HEADER_TEXT}>
              Land your dream teaching job, faster
            </HeaderText>
            <Grid item>
              <HeaderSubText data-testid={ConnectValuePropBannerDataTestIds.SUB_HEADER_TEXT}>
                Save time on tedious apps. Fill out a one-time profile, connect directly with
                principals, and find your perfect match.
                <HeaderSubTextLink
                  data-testid={ConnectValuePropBannerDataTestIds.SUB_HEADER_LINK_TEXT}
                  href="#"
                >
                  Learn more
                </HeaderSubTextLink>
              </HeaderSubText>
            </Grid>
          </HeaderContainer>
          {isUserAuthenticated ? (
            <ConnectMarketingMessageAndButton isLoading={isLoading} actions={actions} />
          ) : (
            <ConnectMarketingInputAndButtonContainer container item spacing={{ xs: 1 }}>
              <Grid item xs={12}>
                <ConnectMarketingInputAndButton
                  onSubmit={handleSubmit}
                  email={email}
                  setEmail={setEmail}
                  isLoading={isLoading}
                  isActive={submitButtonActive}
                />
              </Grid>
              <Grid item textAlign={{ xs: 'center', sm: 'start' }}>
                <TOSTypography>
                  {'I have read, understand, and agree to be bound by the Nimble '}
                  <StyledTOSLink
                    href="https://www.hirenimble.com/terms"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {'terms of use'}
                  </StyledTOSLink>
                  .
                </TOSTypography>
              </Grid>
            </ConnectMarketingInputAndButtonContainer>
          )}
        </HeaderAndInputContainer>
        <ValuePropGraphicContainer item xs={12} lg={5.5}>
          <ValuePropImage
            src={getValuePropBannerGraphic()}
            data-testid={ConnectValuePropBannerDataTestIds.IMAGE_ASSET}
          />
        </ValuePropGraphicContainer>
      </ValuePropBannerContainer>
    </ThemeProvider>
  );
};

const ValuePropBannerContainer = styled(Grid)<{ isAuthenticated: boolean }>(
  ({ theme, isAuthenticated }) => ({
    alignItems: 'center',
    maxWidth: '1130px',
    justifySelf: 'center',
    height: isAuthenticated ? '280px' : '300px',

    [theme.breakpoints.between('md', 'lg')]: {
      padding: '27px 0px 0px',
      height: 'auto',
    },

    [theme.breakpoints.down('md')]: {
      padding: '32px 0px 0px',
      height: 'auto',
    },
  })
);

const HeaderAndInputContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),

  [theme.breakpoints.down('lg')]: {
    justifySelf: 'center',
    justifyItems: 'center',
    alignItems: 'center',
  },

  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(1),
  },
}));

const HeaderContainer = styled(Grid)(({ theme }) => ({
  textAlign: 'start',
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  justifySelf: 'center',
  maxWidth: '584px',
  justifyItems: 'conter',

  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
  },
}));

const HeaderText = styled(Typography)(({ theme }) => ({
  color: theme.palette.gray.dark,
  fontWeight: 600,
  fontSize: '32px',
  lineHeight: '54px',
  fontStyle: 'normal',
  letterSpacing: '-0.5px',

  [theme.breakpoints.down('sm')]: {
    lineHeight: '39px',
    marginBottom: theme.spacing(1),
  },
}));

const HeaderSubText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: theme.typography.heading7.fontSize,
  lineHeight: '24px',
  letterSpacing: '-0.176px',

  [theme.breakpoints.down('sm')]: {
    whiteSpace: 'normal',
    margin: `0 ${theme.spacing(1)} ${theme.spacing(2)}`,
    lineHeight: '24px',
  },

  [theme.breakpoints.down('lg')]: {
    lineHeight: '26px',
  },
}));

const HeaderSubTextLink = styled(Link)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: theme.typography.heading7.fontSize,
  textUnderlineOffset: '3px',
  letterSpacing: '-0.176px',
  '&:hover': {
    color: theme.palette.primary.dark,
  },
  [theme.breakpoints.down('lg')]: {
    lineHeight: '26px',
  },
}));

const ConnectMarketingInputAndButtonContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  maxWidth: '580px',
  alignSelf: 'start',
  gap: theme.spacing(0.5),

  [theme.breakpoints.down('lg')]: {
    maxWidth: '580px',
    gap: theme.spacing(1),
    alignSelf: 'center',
    alignContent: 'center',
    justifySelf: 'center',
  },
}));

const TOSTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.light,
  fontSize: '14px',
  fontWeight: theme.typography.fontWeightRegular,
  fontStyle: 'normal',
  lineHeight: '19.864px',
}));

const StyledTOSLink = styled(Link)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  color: theme.palette.blue.medium,
  fontSize: '14px',
  fontStyle: 'normal',
  lineHeight: '19.864px',

  [theme.breakpoints.between('md', 'lg')]: {
    marginBottom: '17px',
  },
  '&:hover': {
    color: theme.palette.blue.dark,
  },
}));

const ValuePropGraphicContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignSelf: 'end',
  [theme.breakpoints.down('lg')]: {
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const ValuePropImage = styled('img')(() => ({
  width: '100%',
  maxWidth: '580px',
  height: 'auto',
}));
