import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import { styled, Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { HorizontalLinearStepper } from 'sharedComponents/Stepper/HorizontalStepper';

import ExpressInterestAPI from 'features/Connect/api/expressInterestAPI';
import UserSavedSchoolAPI from 'features/Connect/api/userSavedSchoolAPI';
import auth from 'utils/auth';

import { ConnectGatedActions } from 'features/Connect/utils/connectEnums';
import { ConnectProfileFlowButtonGroup } from './SharedComponents/ConnectProfileFlowButtonGroup';
import {
  getBannerContent,
  getGatedActionDisplayInfo,
  PROFILE_FORM_ERROR_MESSAGES,
  updateConnectFirstProfileSetupDateTime,
} from './utils';
import { ConnectCandidatePreferencesDataTestIds } from 'data-testids/ConnectDataTestIds';
import {
  ConnectProfileFlowContainer,
  ConnectProfileFlowFormContainer,
  ConnectProfileFlowPaperContainer,
  ConnectProfileFlowTitle,
  ConnectProfileFlowSelectStyles,
  ConnectProfileFlowTextField,
  ConnectProfileFlowOuterContainer,
} from './styles';
import { MultiSelectWithSearch } from 'sharedComponents/Select';
import { CONNECT_PROFILE_STATE_PREFERENCE_OPTIONS } from 'features/Connect/utils/connectConstants';
import { ConnectAnnouncementBanner } from 'features/Connect/components/ConnectAnnouncmentBanner';
import { GroupHeaderParams, ValidationErrors, ValidationResult } from './types';
import { AutocompleteRenderGroupParams } from '@mui/material/Autocomplete';
import { ProfileFormData } from 'types/connectTypes';
import { ConnectProfileFormDataTestIds } from 'data-testids/ConnectDataTestIds';

export function CreateAndUpdateProfileForm(): React.ReactElement {
  const user = auth.getUser();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { created, modified } = user.preference;
  const createdTime = new Date(created);
  const modifiedTime = new Date(modified);
  // we're using seconds because the created and modified timestamp milliseconds are slighly off when a user is created
  const createdTimeInSeconds = Math.floor(createdTime.getTime() / 1000);
  const modifiedInSeconds = Math.floor(modifiedTime.getTime() / 1000);
  const differenceInSeconds = Math.abs(createdTimeInSeconds - modifiedInSeconds);

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const gatedAction = searchParams.get('action');
  const gatedActionSchool = searchParams.get('school');

  const getInitialSelectedStateOptions = (stateOptions, statePreferences) => {
    return stateOptions.filter((state) => statePreferences?.includes(state.value));
  };
  const getSelectedStateOptionsValues = (selectedOptions) => {
    if (!selectedOptions) return [];
    return selectedOptions.map((state) => state.value);
  };
  const [selectedStateOptions, setSelectedStateOptions] = useState(
    getInitialSelectedStateOptions(CONNECT_PROFILE_STATE_PREFERENCE_OPTIONS, user.preference.states)
  );

  const [userFormData, setUserFormData] = useState({
    firstName: user.first_name || '',
    lastName: user.last_name || '',
    phoneNumber: user.profile.phone_cell || '',
    statePreferences: getSelectedStateOptionsValues(selectedStateOptions) || [],
  });
  const [error, setError] = useState<ValidationErrors>({
    firstName: null,
    lastName: null,
    phoneNumber: null,
    statePreferences: null,
  });
  const [formIsSubmitting, setFormIsSubmitting] = useState<boolean>(false);

  const history = useHistory();
  const { flowDisplayInformation, pageDisplayInformation } = getGatedActionDisplayInfo();

  const sortedStateOptions = [...CONNECT_PROFILE_STATE_PREFERENCE_OPTIONS].sort((a, b) => {
    if (a.isActive && !b.isActive) return -1;
    if (!a.isActive && b.isActive) return 1;
    return a.label.localeCompare(b.label);
  });

  const getStateGroupLabels = (option) =>
    option.isActive
      ? 'ACTIVE STATES'
      : "INACTIVE STATES (we'll let you know via email when we launch)";

  const getStatePlaceholderText = (selectedOptionsLength: number): string => {
    if (selectedOptionsLength >= 2) {
      return 'State(s)';
    }
    if (selectedOptionsLength === 1) {
      return '';
    }
    return 'Select state(s)...';
  };

  const renderGroupHeader = (params: GroupHeaderParams): JSX.Element => {
    return (
      <div key={params.key}>
        <div style={GroupHeaderStyles(theme)}>{params.group}</div>
        {Array.isArray(params.children) ? (
          params.children.map((child, index) => (
            <div key={`group-${params.group}-option-${index}`}>{child}</div>
          ))
        ) : (
          <div>{params.children}</div>
        )}
      </div>
    );
  };

  const getFormTitle = () => {
    return differenceInSeconds <= 2 ? 'Create your profile' : 'Update your profile';
  };

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError({ ...error, [event.target.name]: null });

    setUserFormData({
      ...userFormData,
      [event.target.name]: event.target.value,
    });
  };

  const handleStatePreferenceInputChange = (selectedOptions) => {
    setError({ ...error, statePreferences: null });
    setSelectedStateOptions(selectedOptions);
    setUserFormData({
      ...userFormData,
      statePreferences: getSelectedStateOptionsValues(selectedOptions),
    });
  };

  const validateForm = async (formData: ProfileFormData): Promise<ValidationResult> => {
    const { firstName, lastName, phoneNumber, statePreferences } = formData;
    const errors: ValidationErrors = {};

    if (!firstName) {
      errors.firstName = PROFILE_FORM_ERROR_MESSAGES.firstName;
    }

    if (!lastName) {
      errors.lastName = PROFILE_FORM_ERROR_MESSAGES.lastName;
    }

    // TODO: validate phone number like we do in ATS
    if (phoneNumber && phoneNumber.length < 10) {
      errors.phoneNumber = PROFILE_FORM_ERROR_MESSAGES.phoneNumber;
    }

    if (!statePreferences.length) {
      errors.statePreferences = PROFILE_FORM_ERROR_MESSAGES.statePreference;
    }

    return { isValid: Object.keys(errors).length === 0, errors };
  };

  const hasFormErrors = (error: ValidationErrors) => {
    return !!error.firstName || !!error.lastName || !!error.phoneNumber || !!error.statePreferences;
  };

  useEffect(() => {
    const saveSchool = async () => {
      const savedSchools = await UserSavedSchoolAPI.getSchoolsUserHasSaved();
      const savedSchoolIds = savedSchools.map((s) => s.school_nces_id);
      const isSchoolSaved = savedSchoolIds.includes(gatedActionSchool);
      if (!isSchoolSaved) {
        await UserSavedSchoolAPI.saveSchool(gatedActionSchool);
      }
    };
    if (gatedAction === ConnectGatedActions.SAVE_SCHOOL && gatedActionSchool) saveSchool();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateConnectFirstProfileSetupDateTime(user);
  }, [user]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSaveAndContinue = async () => {
    setFormIsSubmitting(true);
    const { isValid, errors } = await validateForm(userFormData);

    if (!isValid) {
      setError((prev) => ({ ...prev, ...errors }));
      setFormIsSubmitting(false);
      return;
    }

    const { firstName, lastName, phoneNumber, statePreferences } = userFormData;

    const userTalentProfile = {
      userId: user.id,
      firstName,
      lastName,
      phoneCell: phoneNumber,
      preferenceStates: statePreferences,
    };

    await ExpressInterestAPI.updateTalentProfile(userTalentProfile);
    const updatedUser = await auth.getUserAsync();
    auth.setUser(updatedUser);
    history.push(pageDisplayInformation.continueToUrl);
    setFormIsSubmitting(false);
  };

  const getPrimaryButtonLabel = (isMobile: boolean, pageDisplayInformation: any) => {
    return isMobile
      ? pageDisplayInformation?.primaryButtonLabelMobile
      : pageDisplayInformation?.primaryButtonLabel;
  };

  return (
    <ConnectProfileFlowOuterContainer>
      {!!getBannerContent() && <ConnectAnnouncementBanner />}
      <ConnectProfileFlowContainer>
        <HorizontalLinearStepper
          activeStep={pageDisplayInformation.step}
          steps={flowDisplayInformation.stepper.steps}
          mobileSteps={flowDisplayInformation.stepper.mobileSteps}
        />
        <ConnectProfileFlowPaperContainer elevation={4}>
          <ConnectProfileFlowFormContainer component="form">
            <ConnectProfileFlowTitle
              data-testid={ConnectCandidatePreferencesDataTestIds.COMPLETE_PROFILE_TEXT}
              variant="h1"
            >
              {pageDisplayInformation.headerText || getFormTitle()}
            </ConnectProfileFlowTitle>
            <Stack spacing={2} px={{ xs: 2, sm: 8 }} py={{ xs: 1, sm: 3 }}>
              <Box>
                <QuestionLabel
                  htmlFor="first-name"
                  required={pageDisplayInformation.requiredFields.includes('firstName')}
                  aria-required
                  data-testid={ConnectProfileFormDataTestIds.FIRST_NAME_QUESTION_LABEL}
                >
                  First Name
                </QuestionLabel>
                <ConnectProfileFlowTextField
                  hiddenLabel
                  id="first-name"
                  name="firstName"
                  placeholder="Enter your first name"
                  fullWidth
                  size="medium"
                  defaultValue={userFormData.firstName}
                  onChange={handleFormChange}
                  error={!!error.firstName}
                  helperText={error.firstName}
                  data-testid={ConnectProfileFormDataTestIds.FIRST_NAME_INPUT}
                />
              </Box>
              <Box>
                <QuestionLabel
                  htmlFor="last-name"
                  required={pageDisplayInformation.requiredFields.includes('lastName')}
                  aria-required
                  data-testid={ConnectProfileFormDataTestIds.LAST_NAME_QUESTION_LABEL}
                >
                  Last Name
                </QuestionLabel>
                <ConnectProfileFlowTextField
                  hiddenLabel
                  id="last-name"
                  name="lastName"
                  placeholder="Enter your last name"
                  fullWidth
                  size="medium"
                  defaultValue={userFormData.lastName}
                  onChange={handleFormChange}
                  error={!!error.lastName}
                  helperText={error.lastName}
                  data-testid={ConnectProfileFormDataTestIds.LAST_NAME_INPUT}
                />
              </Box>
              <Box>
                <QuestionLabel
                  htmlFor="phone-number"
                  data-testid={ConnectProfileFormDataTestIds.PHONE_NUMBER_QUESTION_LABEL}
                >
                  Phone Number
                </QuestionLabel>
                <ConnectProfileFlowTextField
                  hiddenLabel
                  id="phone-number"
                  name="phoneNumber"
                  placeholder="+1 (555) 000-0000"
                  fullWidth
                  inputProps={{
                    minLength: 10,
                  }}
                  size="medium"
                  defaultValue={userFormData.phoneNumber}
                  onChange={handleFormChange}
                  error={!!error.phoneNumber}
                  helperText={error.phoneNumber}
                  data-testid={ConnectProfileFormDataTestIds.PHONE_NUMBER_INPUT}
                />
              </Box>
              <Box>
                <QuestionLabel
                  htmlFor="state-preferences"
                  required
                  aria-required
                  data-testid={ConnectProfileFormDataTestIds.STATE_PREFERENCES_QUESTION_LABEL}
                >
                  In which state(s) are you looking for work?
                </QuestionLabel>
                <MultiSelectWithSearch
                  values={selectedStateOptions}
                  options={sortedStateOptions}
                  handleChange={(states) => handleStatePreferenceInputChange(states)}
                  optionGroupingFunction={getStateGroupLabels}
                  renderGroupHeader={(params: AutocompleteRenderGroupParams) =>
                    renderGroupHeader(params as GroupHeaderParams)
                  }
                  tagLimit={isMobile ? 2 : 3}
                  placeholder={getStatePlaceholderText(selectedStateOptions.length)}
                  size="medium"
                  sx={ConnectProfileFlowSelectStyles(theme)}
                  showError={!!error?.statePreferences}
                  helperText={error?.statePreferences}
                  dataTestId={ConnectProfileFormDataTestIds.STATE_PREFERENCES_DROPDOWN}
                />
              </Box>
            </Stack>
          </ConnectProfileFlowFormContainer>
        </ConnectProfileFlowPaperContainer>
        <ConnectProfileFlowButtonGroup
          dataTestId={ConnectProfileFormDataTestIds.BUTTON_GROUP}
          primaryButton={{
            dataTestId: ConnectProfileFormDataTestIds.SAVE_AND_CONTINUE_BUTTON,
            primaryButtonLabel: getPrimaryButtonLabel(isMobile, pageDisplayInformation),
            primaryAction: handleSaveAndContinue,
            disabled: hasFormErrors(error),
            isLoading: formIsSubmitting,
          }}
        />
      </ConnectProfileFlowContainer>
    </ConnectProfileFlowOuterContainer>
  );
}

const QuestionLabel = styled(InputLabel)({
  textWrap: 'wrap',
});

const GroupHeaderStyles = (theme: Theme) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  borderTop: '1px solid #ddd',
  fontWeight: theme.typography.fontWeightMedium,
  color: theme.palette.text.light,
});
