import { Box, Drawer, styled, Tab, Toolbar } from '@mui/material';

export const CustomTab = styled(Tab)(({ theme }) => ({
  alignItems: 'start',
  fontSize: theme.typography.body2.fontSize,
  fontWeight: theme.typography.fontWeightMediumBold,
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(1.5),
  paddingTop: theme.spacing(1),
}));

export const DrawerHeader = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));

export const MobileToolbar = styled(Toolbar)(({ theme }) => ({
  width: '100%',
  borderBottom: `1px solid ${theme.palette.border.main}`,
  paddingBottom: theme.spacing(2),
  marginBottom: theme.spacing(3),
}));

export const TabDrawer = styled(Drawer)(() => ({
  width: '100%',
  '.MuiPaper-root': {
    width: '80%',
  },
}));

export const ToolbarHeader = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));
