import { Divider, styled } from '@mui/material';
import HiringTeam from './components/HiringTeam';
import ScorecardSection from './components/ScorecardSection';

const HiringTeamTab: React.FC<{ jobId: number }> = ({ jobId }) => {
  return (
    <>
      <HiringTeam jobId={jobId} />
      <SectionDivider />
      <ScorecardSection jobId={jobId} />
    </>
  );
};

export default HiringTeamTab;

const SectionDivider = styled(Divider)(({ theme }) => ({
  margin: `${theme.spacing(3)} 0`,
}));
