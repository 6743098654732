import axios, { AxiosError } from 'axios';
import { HiringTeamData } from '../types';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { ScorecardData } from './types';

export const useQueryGetHiringTeamData = (jobId: number) => {
  const queryFn = async () => {
    const response = await axios.get<HiringTeamData>(`/api/roles/${jobId}/team/`);
    return response.data;
  };

  return useQuery<HiringTeamData, AxiosError>({
    queryKey: ['hiringTeam', jobId],
    queryFn: queryFn,
    refetchOnWindowFocus: false,
    enabled: Number.isInteger(jobId),
  });
};

export const useQueryGetScorecards = <TData = ScorecardData[]>(
  jobId: number,
  options?: Omit<UseQueryOptions<ScorecardData[], AxiosError, TData>, 'queryKey' | 'queryFn'>
) => {
  const queryFn = async () => {
    const response = await axios.get<ScorecardData[]>(`/api/customscorecards/by_role/`, {
      params: { role_id: jobId },
    });
    return response.data;
  };

  return useQuery<ScorecardData[], AxiosError, TData>({
    queryKey: ['scorecards', jobId],
    queryFn: queryFn,
    refetchOnWindowFocus: false,
    enabled: Number.isInteger(jobId),
    ...options,
  });
};
