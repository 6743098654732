import { Actions } from 'types/connectTypes';

export interface JobDiscoveryHeaderProps {
  isUserAuthenticated: boolean;
  actions: Actions;
}

export interface JobsDiscoveryValuePropBannerProps {
  isUserAuthenticated: boolean;
  actions: Actions;
}

export enum DistanceOptions {
  ANY = 'Any',
  FIVE_MILES = '5',
  TEN_MILES = '10',
  TWENTY_FIVE_MILES = '25',
  FIFTY_MILES = '50',
  SEVENTY_FIVE_MILES = '75',
  ONE_HUNDRED_MILES = '100',
}
